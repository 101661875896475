import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GlobalBlurStyle from './components/GlobalBlurStyle'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary'
import translate from '../../../../../../modules/translate'
import { useAdsContext } from '../../../../../../components/ContextAds'
import { translateFontSizeToTheme } from '../../../../../../modules/font'
import ContentDefault from './components/ContentDefault'
import ContentSplus from './components/ContentSplus'
import ContentCommunityNews from './components/ContentCommunityNews'

const Wrapper = styled.div`
  margin-bottom: 13px;
  position: relative;
  font-family: ${(props) => props.theme.font.primary};
  color: rgba(0, 0, 0, 0.7);
  line-height: ${(props) => props.theme.lineHeight.text};
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.text)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.text)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.text)};
  }

  iframe {
    max-width: 100%;
  }
`

const ContentDetailArticle = ({
  htmlContent,
  plus,
  isCommunityNews,
  plusTeaser,
  plusContent,
  embeds,
  adTargeting,
  showAds,
  editor,
  fontSize,
  inverted,
  articleLayout,
  isSportnews,
  author,
  ...props
}) => {
  const { adsDispatch } = useAdsContext()

  useEffect(() => {
    adsDispatch('ready')
  }, [])

  const errorOptions = {
    component: translate('article'),
    link: '/',
    linkText: translate('error_try_homepage'),
    logError: 'Content Detail Article',
  }

  const contentOptions = {
    inverted: inverted,
    fontSize: fontSize,
    showAds: showAds,
    adTargeting: adTargeting,
    embeds: embeds,
    editor: !isSportnews && !author && editor,
    articleLayout: articleLayout,
  }

  return (
    <ErrorBoundary {...errorOptions}>
      <Wrapper className="content" fontSize={fontSize} {...props}>
        <GlobalBlurStyle />
        {plus && (
          <ContentSplus
            plusContent={plusContent}
            plusTeaser={plusTeaser}
            grantAccess={isSportnews}
            contentOptions={contentOptions}
          />
        )}
        {!plus && !isCommunityNews && <ContentDefault html={htmlContent} {...contentOptions} />}
        {!plus && isCommunityNews && <ContentCommunityNews html={htmlContent} contentOptions={contentOptions} />}
      </Wrapper>
    </ErrorBoundary>
  )
}

ContentDetailArticle.propTypes = {
  htmlContent: PropTypes.string,
  plusTeaser: PropTypes.string,
  plusContent: PropTypes.string,
  embeds: PropTypes.object,
  plus: PropTypes.bool,
}

ContentDetailArticle.defaultProps = {
  plusTeaser: null,
  plusContent: null,
}

const propsAreEqual = (prevProps, nextProps) => {
  return prevProps.fontSize === nextProps.fontSize
}

export default memo(ContentDetailArticle, propsAreEqual)
